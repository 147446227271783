@media (max-width: 700px) {
  .home-profile {
    display: none;
  }
  .home-profile-txt {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 600px) {
  Button .span-btn {
    /* display: none; */
  }
  .btn-htr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
