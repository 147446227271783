body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1819;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container2 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container2 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container2 {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container2 {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container2 {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container2 {
    width: 1340px;
    max-width: 100%;
  }
}
@media (min-width: 2000px) {
  .container2 {
    width: 90%;
    max-width: 100%;
  }
}
.h-100 {
  height: 100% !important;
}
.bg-login {
  background: linear-gradient(45deg, #000000, #595959);
}
.signup-button {
  height: 50px;
  border-radius: 5px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: black !important;
  border: 2px solid black !important;
}
.primary-button {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 16px;
  height: 100%;
  background-color: #1677ff;
  color: white !important;
  border: 0px !important
}
.primary-button:hover {
  background-color: #1d529b;
}
.mx-15px {
  margin-left: 15px;
  margin-right: 15px;
}
.sec-button {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  height: 100%;
  background-color: transparent;
  color: black !important;
  border: 2px solid white !important;
}
.sec-button:hover {
  background-color: white;
}
.header-paragraph {
  color: black;
}
.body-style {
  text-align: left;
  min-height: calc(100vh - 180px);
  color: rgb(255, 255, 255);
  background-color: transparent;
}

h4 {
  margin-top: 0px;
  margin-bottom: 5px;
}
