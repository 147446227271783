$base-text-color: #3a3f4e;

.container {
  width: 100%;
  background: #f6f6f6;
  padding: 1rem 3.25rem;
}

.bg {
  min-height: 100vh;
  background: linear-gradient(45deg, #c9177f, #ea1b25, #f89d1d);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

.form-box {
  display: flex;
  align-items: center;
  padding: 25px 40px;
  margin: 0 auto;
  background-color: #5b5b5bb3 !important;
  border-radius: 15px;
  background: transparent;
  color: #fff;
}

.form-box input {
  padding: 8px 5px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #efefef;
}

.form-box h1 {
  text-align: center;
}

.login-link {
  text-decoration: none;
  color: #fff;
}

.login-link:hover {
  color: #fff;
}

h1 {
  color: $base-text-color;
}

// .container Button {
// margin-top: 20px;
//   width: 70%;
// }

.btn-card {
  display: flex;
  justify-content: center;
}

.profile-head {
  /* height: 120px; */
  display: flex;
  align-items: center;
}

.profile-icon {
  font-size: 50px;
  background-color: #1677ff;
  padding: 12px;
  border-radius: 50%;
  color: #fff;
}
.col-profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-name {
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.profile-body-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.profile-body-title {
  color: #888888;
}

.profile-body-text {
  font-size: 16px;
}

// .ant-table-cell button {
//   margin: 0;
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   border: 0;
//   background-color: #1677ff;
//   color: #fff;
//   cursor: pointer;
// }

.ant-table-cell button:hover {
  background-color: #4096ff;
}

.home-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.home-profile {
  font-size: 55px;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  color: #191c24;
  background-color: #1677ff;
}

.home-profile-txt {
  margin-left: 22px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.home-bg-profile {
  height: 130px;
  display: flex;
  align-items: center;
}

.animation-fade {
  /* color: #fff; */
  animation: fadeInRight 0.5s ease-in-out;
}

.btn-history {
  color: #1677ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.align-right {
  text-align: right;
}

.card-add-funds {
  margin: auto;
  width: 400px;
}

.icon-menubar {
  font-size: 16px !important;
  padding: 6px;
}

.form-package {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 1.5rem 7rem;
  color: $base-text-color;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
  }
}

.card-home {
  .ant-card-head {
    border-bottom: 1px solid #2d313d !important;
  }

  .ant-pagination-item-link {
    margin: 0 !important;
  }
}

.modal-profile {
  // .ant-modal-content {
  //   background-color: #191c24 !important;
  // }

  // .ant-modal-title {
  //   background-color: #191c24 !important;
  //   color: #fff !important;
  // }

  // .ant-modal-close-icon {
  //   color: #fff;
  // }

  // .ant-pagination-item {
  //   background-color: #191c24 !important;
  //   border-color: #fff !important;
  // }

  // .ant-pagination-item a {
  //   color: #fff !important;
  // }

  // .ant-pagination-item a:hover {
  //   color: #878787 !important;
  // }
}

.card-package {
  .ant-card-cover {
    background-color: #191c24 !important;
  }
}

.modal-payment-history {
  .ant-modal-content {
    background-color: #191c24 !important;
  }

  .ant-modal-title {
    background-color: #191c24 !important;
    color: #fff !important;
  }

  .ant-modal-close-icon {
    color: #fff;
  }

  .ant-pagination-item-link {
    color: #fff !important;
  }

  .ant-pagination-item {
    background-color: #191c24 !important;
    border-color: #fff !important;
  }

  .ant-pagination-item a {
    color: #fff !important;
  }

  .ant-pagination-item a:hover {
    color: #878787 !important;
  }

  .ant-table {
    background-color: #1d2330 !important;
    color: #fff !important;
  }

  .ant-table-cell {
    border-bottom: 1px solid #2d313d !important;
  }

  .ant-table-cell-row-hover {
    background: #191c24 !important;
  }
}

.form-add-fund {
  .ant-row {
    justify-content: center;
  }

  .ant-col {
    text-align: center;
  }
}

.card-vol-1 {
  background-color: white;
  color: #2d313d !important;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e3e3e3;

  .ant-card-head-title {
    color: #2d313d;
  }
}

.navbar-custom {
  // width: 220px;
  height: calc(100vh - 48.75px);
  background-color: white;
  color: #878787;
  font-size: 14px;

  > li:hover {
    background-color: #f3f3f3 !important;
    color: #1677ff !important;
    border-left: 5px solid #1677ff !important;
    border-radius: 0px 25px 25px 0px !important;
  }

  > li.nav-sub:hover {
    background-color: white !important;
    color: #3e3e3e !important;
    border-left: 0px !important;
    border-radius: 0px !important;
  }
  .ant-menu-item {
    margin-inline: 0;
  }
}

.ant-menu-item-selected {
  background-color: #f3f3f3 !important;
  color: white !important;
  border-left: 5px solid #1677ff !important;
  border-radius: 0px 25px 25px 0px !important;

  .icon-menubar {
    margin-right: 6px;
    background-color: #1677ff;
    padding: 6px;
    border-radius: 6px;
  }

  .ant-menu-item-icon {
    color: white !important;
  }

  .ant-menu-title-content {
    color: #1677ff !important;
  }
}

.nav-sub {
  font-size: 12px;
  font-weight: bold;
  border-left: 0px !important;
  border-radius: 0px !important;
  background-color: white !important;
  cursor: default !important;
  .ant-menu-title-content {
    color: $base-text-color !important;
  }
}

.package-form {
  padding: 20px;
  border-radius: 4px;
  margin-top: 20px;

  .ant-card-body {
    width: 100%;
    padding: 0;
  }
}

.header-style {
  text-align: center;
  color: rgb(255, 255, 255);
  height: 100px;
  line-height: 100px;
  background-color: transparent;
}

.nav-header {
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.highlight-text {
  font-weight: bold;
  color: #1677ff;
  cursor: pointer;
}

.card-dashboard {
  background-color: white;
  border: 1px solid #e3e3e3;
  color: #fff;
  min-height: 130px;
}

.ant-layout-header {
  padding-inline: 25px !important;
}

// ==== RESPONSIVE ==== //
@media (max-width: 800px) {
  .col-profile-icon {
    /* display: none; */
  }

  .etc {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .d-none-md {
    display: none !important;
  }
  .header-style {
    padding-inline: 0px !important;
  }
}
@media (min-width: 768px) {
  .header-style {
    padding-inline: 50px;
  }
}

@media (max-width: 500px) {
  .form-package {
    padding: 0;
    margin-top: 55px;
  }
}

.footer {
  text-align: center;
  background-color: #ffffff;
  color: $base-text-color;
  min-width: 320px;
  padding: 16px 50px;
}
@media (max-width: 500px) {
  .navbar-custom .ant-menu-item {
    text-align: center;
  }
  .navbar-custom .ant-menu-item {
    text-align: center;
  }
  .container {
    padding: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.ant-layout-content {
  min-width: 320px;
}
.ant-card-cover > img {
  object-fit: cover;
}
@media (max-width: 380px) {
  .d-none-380px {
    display: none !important;
  }
}
@media (max-width: 380px) {
  .d-none-380px {
    display: none !important;
  }
}
@media (min-width: 501px) {
  .ant-layout .ant-layout-sider {
    flex: 0 0 220px;
    max-width: 220px;
    min-width: 220px;
    width: 220px;
  }
}
.ant-layout .ant-layout-sider {
  // flex: 0 0 220px !important;
  // max-width: 220px !important;
  // min-width: 220px !important;
  // width: 220px !important;
  height: 100% !important;
  background: #ffffff !important;
}

.less-padding {
  padding: 4px 10px;
}

.clickable-row {
  cursor: pointer;
}

.form-credit {
  .ant-col-16 {
    max-width: 100%;
  }
}

.bt-link-footer {
  color: white;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
}

// === CUSTOM ANTD TAG === ///
.ant-tag-green {
  color: #fff !important;
  background: #389e0d !important;
  border-color: #389e0d !important;
}

.ant-tag-orange {
  color: #fff !important;
  background: #d46b08 !important;
  border-color: #d46b08 !important;
}

.ant-tag-red {
  color: #fff !important;
  background: #cf1322 !important;
  border-color: #cf1322 !important;
}

.ant-tag-magenta {
  color: #fff !important;
  background: #c41d7f !important;
  border-color: #c41d7f !important;
}

.ant-tag-blue {
  color: #fff !important;
  background: #2299ff !important;
  border-color: #2299ff !important;
}

.ant-tag-purple {
  color: #fff !important;
  background: #531dab !important;
  border-color: #531dab !important;
}

.coin-icon {
  color: #e1b000;
  font-size: 16px;
}

.package-title {
  font-weight: bold;
  font-size: 14px;
}

.package-detail {
  font-size: 16px;
}

.package-card-hightlight {
  padding: 0px;
  border-top: 2px solid #1677ff;
}

.card-upload {
  .ant-upload-select {
    width: 100%;
  }
}
.tag-l{
  height: 32px !important;
  font-size: 16px;
  line-height: 30px;
}